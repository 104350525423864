import type { Matchup, Score, TeamId } from '~/types';

import firstHalf from '@images/long/1st-half.png';
import firstNoBlock from '@images/long/1st-no-block.png';
import secondHalf from '@images/long/2nd-half.png';
import secondNoBlock from '@images/long/2nd-no-block.png';
import thirdNoBlock from '@images/long/3rd-no-block.png';
import final from '@images/long/final.png';
import halftime from '@images/long/halftime.png';
import overtime from '@images/long/overtime.png';
import pregame from '@images/long/pregame.png';
// import timeoutImg from '@images/timeout.png';
import firstHalfShort from '@images/short/1st-half.png';
import firstNoBlockShort from '@images/short/1st-no-block.png';
import secondHalfShort from '@images/short/2nd-half.png';
import secondNoBlockShort from '@images/short/2nd-no-block.png';
import thirdNoBlockShort from '@images/short/3rd-no-block.png';
import finalShort from '@images/short/final.png';
import frFirstHalfShort from '@images/short/fr/1st-half.png';
import frFirstNoBlockShort from '@images/short/fr/1st-no-block.png';
import frSecondHalfShort from '@images/short/fr/2nd-half.png';
import frSecondNoBlockShort from '@images/short/fr/2nd-no-block.png';
import frThirdNoBlockShort from '@images/short/fr/3rd-no-block.png';
import frFinalShort from '@images/short/fr/final.png';
import frHalftimeShort from '@images/short/fr/halftime.png';
import frOvertimeShort from '@images/short/fr/overtime.png';
import frPregameShort from '@images/short/fr/pregame.png';
import halftimeShort from '@images/short/halftime.png';
import overtimeShort from '@images/short/overtime.png';
import pregameShort from '@images/short/pregame.png';
import empty from '@images/short/empty.png';
import breakImg from '@images/short/break.png';
import regulation from '@images/short/regulation.png';
import { Stage, Status } from './types';

// import timeoutImgShort from '@images/short/timeout.png';

export const allStages = {
  'pre-game': 'Pre-game',
  '1st-half': '1st half',
  '1st-sudden-death': '1st no block',
  'half-time': 'Halftime',
  '2nd-half': '2nd half',
  '2nd-sudden-death': '2nd no block',
  'tie-breaker': 'Overtime',
  '3rd-sudden-death': 'Final no block',
  final: 'Final',
};

export const allStagesWithImg = {
  break: {
    imgSrc: { en: { short: breakImg, long: breakImg }, fr: { short: breakImg, long: breakImg } },
    label: 'Break',
  },
  empty: {
    imgSrc: { en: { short: empty, long: empty }, fr: { short: empty, long: empty } },
    label: 'Empty court',
  },
  regulation: {
    imgSrc: { en: { short: regulation, long: regulation }, fr: { short: regulation, long: regulation } },
    label: 'Regulation',
  },
  'pre-game': {
    imgSrc: { en: { short: pregameShort, long: pregame }, fr: { short: frPregameShort, long: pregame } },
    label: 'Pre-Game',
  },
  '1st-half': {
    imgSrc: { en: { short: firstHalfShort, long: firstHalf }, fr: { short: frFirstHalfShort, long: firstHalf } },
    label: '1st Half',
  },
  '1st-sudden-death': {
    imgSrc: {
      en: { short: firstNoBlockShort, long: firstNoBlock },
      fr: { short: frFirstNoBlockShort, long: firstNoBlock },
    },
    label: '1st No Blocking',
  },
  'half-time': {
    imgSrc: { en: { short: halftimeShort, long: halftime }, fr: { short: frHalftimeShort, long: halftime } },
    label: 'Half Time',
  },
  '2nd-half': {
    imgSrc: { en: { short: secondHalfShort, long: secondHalf }, fr: { short: frSecondHalfShort, long: secondHalf } },
    label: '2nd Half',
  },
  '2nd-sudden-death': {
    imgSrc: {
      en: { short: secondNoBlockShort, long: secondNoBlock },
      fr: { short: frSecondNoBlockShort, long: secondNoBlock },
    },
    label: '2nd No Blocking',
  },
  'tie-breaker': {
    imgSrc: { en: { short: overtimeShort, long: overtime }, fr: { short: frOvertimeShort, long: overtime } },
    label: 'Overtime',
  },
  '3rd-sudden-death': {
    imgSrc: {
      en: { short: thirdNoBlockShort, long: thirdNoBlock },
      fr: { short: frThirdNoBlockShort, long: thirdNoBlock },
    },
    label: 'Final No Blocking',
  },
  final: {
    imgSrc: { en: { short: finalShort, long: final }, fr: { short: frFinalShort, long: final } },
    label: 'Final',
  },
};

export const stageOrder = {
  'pre-game': 1,
  '1st-half': 2,
  '1st-sudden-death': 3,
  'half-time': 4,
  '2nd-half': 5,
  '2nd-sudden-death': 6,
  'tie-breaker': 7,
  '3rd-sudden-death': 8,
  final: 9,
};

export const getInitData = (
  gameTime: number,
  getTeamName?: (team?: TeamId, match?: Matchup) => string | void,
  match?: Matchup,
  currentScore?: Score,
  prevTeams?: any,
) => {
  const team1 = getTeamName?.(match?.player1, match);
  const team2 = getTeamName?.(match?.player2, match);
  return {
    team1Name: prevTeams?.team1 ? prevTeams.team1 : team1,
    team2Name: prevTeams?.team2 ? prevTeams.team2 : team2,
    team1Score: currentScore?.player1.score || 0,
    team2Score: currentScore?.player2.score || 0,
    team1Penalties: [],
    team2Penalties: [],
    status: 'paused' as Status,
    gameStage: 'pre-game' as Stage,
    reverseTeams: false,
    timeLeft: gameTime || 0,
    backgroundTimeLeft: 0,
  };
};
