import { useEffect, useState } from 'react';

import { useParams, useSearchParams } from '@remix-run/react';

import styles from '../styles.module.css';

import { allStagesWithImg } from '../constants';
import type { Settings, Stage, Status } from '../types';

export const Timer = ({
  status,
  timeLeft,
  gameStage,
  editable,
  isGlobalClock,
  variant,
  isBreak,
  isEmptyCourt,
  hasSecondHalf,
  updateTime,
}: {
  timeLeft: number;
  gameStage: Stage;
  status: Status;
  hasSecondHalf?: boolean;
  isBreak: boolean;
  isEmptyCourt: boolean;
  variant?: 'long' | 'short' | 'control';
  editable?: boolean;
  isGlobalClock?: boolean;
  updateTime?: (newTime: number) => void;
}) => {
  const [searchParams] = useSearchParams();
  const lang: 'fr' | 'en' = searchParams.get('lang') ? (searchParams.get('lang') as 'fr' | 'en') : 'en';
  const mins = ~~((timeLeft % 3600) / 60);
  const secs = ~~timeLeft % 60;
  const [localMins, setLocalMins] = useState(`${mins.toString().length === 1 ? 0 : ''}${mins}`);
  const [localSecs, setLocalSecs] = useState(`${secs.toString().length === 1 ? 0 : ''}${secs}`);
  const { court, id: eventId } = useParams();

  useEffect(() => {
    const mins = ~~((timeLeft % 3600) / 60);
    const secs = ~~timeLeft % 60;
    setLocalMins(`${mins.toString().length === 1 ? 0 : ''}${mins}`);
    setLocalSecs(`${secs.toString().length === 1 ? 0 : ''}${secs}`);
  }, [court, timeLeft, eventId]);

  const formattedTime = `${localMins}:${localSecs}`;

  const handleKeyDown = (e: any) => {
    if (e.key === 'e') e.preventDefault();
  };

  const handleChange = (input: 'minutes' | 'seconds', e: any) => {
    let newTime = 0;
    let newValue = e.currentTarget.value.slice(e.currentTarget.value.length - 2);
    newValue = newValue > 0 ? newValue : 0;
    if (input === 'minutes') {
      setLocalMins(newValue);
      newTime = Number(newValue) * 60 + Number(localSecs);
    } else {
      setLocalSecs(newValue);
      newTime = Number(localMins) * 60 + Number(newValue);
    }

    updateTime?.(newTime);
  };
  const currentVariant = variant || 'short';

  return (
    <div>
      <span className={styles.time}>
        {editable ? (
          <>
            <input
              disabled={status === 'running' || (gameStage === 'final' && !isGlobalClock)}
              onChange={(e) => handleChange('minutes', e)}
              type="number"
              onKeyDown={handleKeyDown}
              value={localMins}
            />
            :
            <input
              disabled={status === 'running' || (gameStage === 'final' && !isGlobalClock)}
              onChange={(e) => handleChange('seconds', e)}
              type="number"
              onKeyDown={handleKeyDown}
              value={localSecs}
            />
          </>
        ) : (
          formattedTime
        )}
      </span>
      <span
        className={`${styles.status} ${currentVariant} ${gameStage
          .replace('1st-', '')
          .replace('2nd-', '')
          .replace('3rd-', '')}`}
      >
        {currentVariant !== 'control' && (
          <img
            src={
              allStagesWithImg[
                isBreak
                  ? 'break'
                  : isEmptyCourt
                    ? 'empty'
                    : !hasSecondHalf && gameStage === '1st-half'
                      ? 'regulation'
                      : gameStage
              ].imgSrc[lang][currentVariant]
            }
            alt={allStagesWithImg[gameStage].label}
            width={currentVariant === 'short' ? 147 : 108}
            height={currentVariant === 'short' ? 55 : 80}
          />
        )}
      </span>
    </div>
  );
};
