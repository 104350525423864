import { noop } from 'lodash';
import type { PropsWithChildren } from 'react';
import { createContext, useEffect, useRef, useState } from 'react';
import { uid } from '~/utils/uid';

// Create Context Object
export const WebSocketContext = createContext<{
  sendMessage: (obj: { name: string; data: any }, callback?: (data: any) => void) => void;
  listenToMessage: (messageName: string, callback: (data: any) => void) => void;
  stopListeningToMessage: (messageName: string) => void;
  isConnected: boolean;
}>({
  sendMessage: noop,
  listenToMessage: noop,
  stopListeningToMessage: noop,
  isConnected: false,
});

// Create a provider for components to consume and subscribe to changes
export const WebSocketProvider = ({ url, children }: PropsWithChildren<{ url: string }>) => {
  const innerIsConnected = useRef(false);
  const [isConnected, setIsConnected] = useState(false);
  const initiated = useRef(false);
  const ws = useRef<WebSocket | undefined>();
  const callbackObject = useRef<Record<string, (data: any) => void>>({});
  const messageTracking = useRef<Record<string, (data: any) => void>>({});
  const retryQueue = useRef<any[]>([]);

  const handleMessage = (event: MessageEvent) => {
    // console.log('test', event.data);

    try {
      const { name, uid, data } = JSON.parse(event.data || {});
      if (name === `callback`) {
        callbackObject.current[uid]?.(data);
        delete callbackObject.current[uid];
      }
      if (messageTracking.current[name]) {
        messageTracking.current[name](data);
      }
    } catch (error) {
      console.error(error, event.data);
    }
  };

  function connect() {
    try {
      if (typeof document !== undefined) {
        var HOST = url.replace(/^http/, 'ws');
        ws.current = new WebSocket(HOST);

        ws.current.onmessage = handleMessage;

        ws.current.onopen = function (event) {
          setIsConnected(true);
          innerIsConnected.current = true;
          while (retryQueue.current.length) {
            ws.current?.send(retryQueue.current.shift());
          }
        };

        ws.current.onclose = function (e) {
          setIsConnected(false);
          innerIsConnected.current = false;
          setTimeout(function () {
            connect();
          }, 1000);
        };

        ws.current.onerror = function (err: any) {
          setIsConnected(false);
          innerIsConnected.current = false;
          console.error('Socket encountered error: ', err.message);
        };
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!initiated.current) {
      connect();
      initiated.current = true;
    }
  }, []);

  const sendMessage = ({ name, data }: { name: string; data: any }, callback?: (data: any) => void) => {
    const id = uid();
    if (!ws.current || !innerIsConnected.current) {
      retryQueue.current.push(JSON.stringify({ name, data: { ...data, uid: id } }));
    } else {
      ws.current?.send(JSON.stringify({ name, data: { ...data, uid: id } }));
    }

    if (callback) {
      callbackObject.current[id] = callback;
    }
  };

  const listenToMessage = (id: string, callback: (data: any) => void) => {
    if (messageTracking.current[id]) {
      throw new Error(`Event ${id} is already being listened to.`);
    }
    messageTracking.current[id] = callback;
  };

  const stopListeningToMessage = (id: string) => {
    if (messageTracking.current[id]) {
      delete messageTracking.current[id];
    }
  };

  return (
    <WebSocketContext.Provider value={{ sendMessage, listenToMessage, stopListeningToMessage, isConnected }}>
      {children}
    </WebSocketContext.Provider>
  );
};
